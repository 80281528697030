import React, { Component } from 'react';



class Space extends Component {
    state = {  } 
    render() { 
        return (
            <div className='space'></div>
        );
    }
}
 
export default Space;