import React, { Component } from 'react';

const produkte = ["Brot", "Butter", "Milch", "Käse", "Honig", "Nutella", "Ananas"];
class Schleife extends Component {
    state = {  } 
    render() { 
        const sortierteProdukte = produkte.sort((a, b) => a.localeCompare(b));

        return <>

        
        
        <div className='container text-center'>
        <div className='row'>
        <h2>Loop Liste</h2>
        {/*
        {produkte.map((produkt) => (
            <li key={produkt.id}>{produkt}</li>
        ))};
        */}

        {sortierteProdukte.map((produkt, index) => (
            <li key={index}>{produkt}</li>
        ))}
        

        </div>
        </div>
        
        
        </>;
    }
}
 
export default Schleife;