import Bigbild from "../components/Bigbild";
import "./Links.css"

export function Links(){
    return <div>
    
    <Bigbild/>
    <div className="link-bereich">
    <a href="https://www.chefblogger.me" target="_blank" rel="noreferrer"  className="btn btn-primary">Chefblogger</a>

    <a href="https://mastodon.maechler.cloud/" target="_blank" rel="noreferrer"  className="btn btn-primary">Mastodon</a>

    <a href="https://video.maechler.cloud/" target="_blank" rel="noreferrer"  className="btn btn-primary">Medienserver</a>
    
    </div>
    
    
    
    </div>
}