import React, { Component } from 'react';
import "./Footer.css";

class Footer extends Component {
    state = {  } 
    render() { 
        return <div className='footer'>Footer Bereich</div>;
    }
}

export default Footer;

