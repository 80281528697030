import { Link, Route, Routes } from "react-router-dom";
import { Home } from "./seiten/Home";
import { Uebermich } from "./seiten/Uebermich";
import { Error404 } from "./seiten/Error404";
import Navbar from "./components/Navbar";
import { Links } from "./seiten/Links";
import { Loops } from "./seiten/Loops";
import Footer from "./components/Footer";
import { Mysql } from "./seiten/Mysql";
import { Blog } from "./seiten/blog"; // Blog importieren
import './seiten/style.css';
import Space from "./components/Space";

function App() {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ueber_mich" element={<Uebermich />} />
                <Route path="/links" element={<Links />} />
                <Route path="/loops" element={<Loops />} />
                <Route path="/mysql" element={<Mysql />} />
                <Route path="/blog/*" element={<Blog />} /> {/* Blog-Routen hier */}
                <Route path="*" element={<Error404 />} />
            </Routes>
            <Space/>
            <Footer />
        </>
    );
}

export default App;
