import React, { useState } from "react";

export function Uebermich() {
  // State für Eingabefelder
  const [formData, setFormData] = useState({
    email: "",
    bemerkung: "",
  });

  const [message, setMessage] = useState("");

  // Funktion zum Behandeln von Eingabeveränderungen
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Absenden der Daten
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("send.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Überprüfe, ob die Netzwerkantwort in Ordnung ist
      if (!response.ok) {
        throw new Error("Netzwerkantwort war nicht in Ordnung");
      }

      // Serverantwort verarbeiten
      const result = await response.json();
      console.log("Result:", result);

      // Erfolgsmeldung anzeigen und Formular zurücksetzen
      if (result.status === "success") {
        setMessage("E-Mail wurde erfolgreich abgeschickt.");
        setFormData({ email: "", bemerkung: "" }); // Zurücksetzen des Formulars
      } else {
        setMessage("Ups, da ging was schief.");
      }
    } catch (error) {
      setMessage("Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.");
      console.error("Fehler:", error);
    }
  };

  return (
    <div className="container">
      <h1>Über mich</h1>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit...</p>

      <h2>Kontaktformular</h2>
      
      {message && <div className="alert alert-primary" role="alert">{message}</div>}
      
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">E-Mail Adresse</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="mein@name.de"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="bemerkung" className="form-label">Bemerkung</label>
          <textarea
            className="form-control"
            id="bemerkung"
            rows="5"
            value={formData.bemerkung}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <button type="submit" className="btn btn-primary">Abschicken</button>
      </form>

      
    </div>
  );
}
