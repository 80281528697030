import React, { Component } from 'react';
import axios from 'axios';

class Dbadd extends Component {
    constructor(props) {
        super(props);
        // Initialisierung des Zustands
        this.state = {
            vorname: '',
            name: '',
            errorMessage: '' // Fehlernachricht hinzufügen
        };
    }

    handleSubmit = () => {
        const { vorname, name } = this.state; // Zustand destructuring
        if (vorname.length === 0 || name.length === 0) {
            this.setState({ errorMessage: "ERROR: Bitte füllen Sie alle Felder aus." });
        } else {
            const url = "https://react.projekte.dev/mysql.php";
            let fData = new FormData();
            fData.append('vorname', vorname);
            fData.append('name', name);
            axios.post(url, fData)
                .then(response => {
                    alert(response.data);
                    // Felder zurücksetzen bei Erfolg
                    this.setState({ vorname: '', name: '', errorMessage: '' });

                    if (typeof this.props.onSuccess === 'function') {
                    this.props.onSuccess();
                }



                })
                .catch(error => {
                    this.setState({ errorMessage: "ERROR: " + error });
                });
        }
    };

    render() {
        const { vorname, name, errorMessage } = this.state; // Zustand destructuring für die Eingabewerte
        return (
            <div>
                <h3>Formular</h3>
                {/* {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>} */}
                <form>
                    <div className='mb-3'>
                        <label htmlFor="vorname" className='form-label'>Vorname:</label>
                        <input
                            className='vorname form-control'
                            name="vorname"
                            id="vorname"
                            value={vorname}
                            onChange={(e) => this.setState({ vorname: e.target.value })}
                        />
                    </div>

                    <div className='mb-3'>
                        <label htmlFor="name" className='form-label'>Name:</label>
                        <input
                            className='name form-control'
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                    </div>

                    <div className='submitbereich'>
                        <input
                            type='button'
                            name="submit"
                            id="submit"
                            value="Register"
                            onClick={this.handleSubmit}
                            className='btn btn-dark'
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default Dbadd;
