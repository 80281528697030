import React, { useState } from 'react';

import Dbadd from "../components/Dbadd";
import Dbread from "../components/Dbread";

export function Mysql() {
    const [refreshKey, setRefreshKey] = useState(0);

    // Funktion, die aufgerufen wird, wenn ein neuer Eintrag hinzugefügt wurde
    const handleSuccess = () => {
        // Den Zustand ändern, um Dbread neu zu laden
        setRefreshKey(prevKey => prevKey + 1);
    };

    return (
        <>
        <div class="container">
            <h2>Add to MySQL</h2>
            
            {handleSuccess && <Dbadd onSuccess={handleSuccess} />}
            <hr />
            {/* Dbread wird neu gerendert, wenn sich refreshKey ändert */}

            <Dbread key={refreshKey} />
            </div>
        </>
    );
}

