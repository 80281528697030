import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';
import { Link, Routes, Route, useParams } from 'react-router-dom';


{/*
https://divi.projekte.dev/graphql?query={posts{nodes{title}}}
*/}

{/* https://divi.projekte.dev/graphql */}
const client = new ApolloClient({
    uri: 'https://www.chefblogger.me/graphql',
    cache: new InMemoryCache()
});

const GET_POSTS = gql`
  query GetPosts {
    posts(first:20) {
      nodes {
        id
        title
        content
      }
    }
  }
`;

const GET_POST_BY_ID = gql`
  query GetPostById($id: ID!) {
    post(id: $id) {
      title
      content
    }
  }
`;





const PostsList = () => {
    const { loading, error, data } = useQuery(GET_POSTS, {
      fetchPolicy: 'cache-and-network',
    });
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Ups: {error.message}</p>;

    {/* const letztePosts = data.posts.nodes.slice(0, 5); */}

    return (
        <div>
            
              {/* data.posts.nodes <-> letztePosts  */ }
                {/* {data.posts.nodes.map((post) => ( */}
                {data.posts.nodes.map((post) => (

                    <div key={post.id}>
                        <Link to={`/blog/post/${post.id}`} className='links'>
                        
                            <div className='bloglink'>{post.title}</div>
                        </Link>
                    </div>
                ))}
            
        </div>
    );
};

const PostDetail = () => {
    const { postId } = useParams();
    const { loading, error, data } = useQuery(GET_POST_BY_ID, {
        variables: { id: postId },
    });

    if (loading) return <p>Loading...</p>;
    if (error) {
        console.error(error);
        return <p>Ups: {error.message}</p>;
    }

    const post = data.post;

    return (
        <div>
            <h2>{post.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
    );
};

export function Blog() {
    return (
        <ApolloProvider client={client}>
            <h2>Blog</h2>
            <Routes>
                <Route path="/" element={<PostsList />} />
                <Route path="post/:postId" element={<PostDetail />} />
            </Routes>
        </ApolloProvider>
    );
}


