import React, { Component } from 'react';

class Hero extends Component {
    state = {  } 
    render() { 
        return <div className='container text-center'>
        <div className='row'>
            <div className="col-sm"><img src='img/eric.jpg' className='img-thumbnail'/></div>
            <div className="col-sm">
            <p>Hallo und herzlich Willkommen auf meiner kleinen Webseite.</p>
            <p>Hier findet man verschiedene Infos über mich.</p>
            </div>
        </div>
    </div>;;
    }
}
 
export default Hero;