import Schleife from "../components/Schleife";


export function Loops(){
    return (
    <>
    <Schleife/>
    
    </>
)
}