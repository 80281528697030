import Hero  from "../components/Hero";
import { Helmet } from "react-helmet";


export function Home(){
    return (
    <>
    <Helmet>
    <title>React Demo Webseite</title>
    <meta name="description" content="Das ist eine reine Demo Webseite"/>
    </Helmet>
    <Hero/>
    
    </>

)
}