import React, { Component } from 'react';
import axios from 'axios';

class Dbread extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eintraege: [], // Liste der Einträge
            errorMessage: '' // Fehlernachricht
        };
    }

    // Funktion zum Abrufen der Daten
    fetchData = () => {
        axios.get('https://react.projekte.dev/mysql_read.php') // Hier wird davon ausgegangen, dass 'mysql_read.php' die Daten aus der DB liest
            .then(response => {
                this.setState({ eintraege: response.data }); // Daten in den Zustand laden
            })
            .catch(error => {
                this.setState({ errorMessage: "ERROR: " + error });
                
            });
    }

    componentDidMount() {
        // Daten sofort nach dem Laden der Komponente abrufen
        this.fetchData();

        // Polling-Mechanismus: Alle 5 Sekunden Daten abrufen
        this.interval = setInterval(this.fetchData, 5000);
    }

    componentWillUnmount() {
        // Polling stoppen, wenn die Komponente entfernt wird
        clearInterval(this.interval);
    }

    render() {
        const { eintraege, errorMessage } = this.state;

        return (
            <div>
                <h3>Einträge</h3>
                {/* {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>} */}
                <ul>
                    {eintraege.length > 0 ? (
                        eintraege.map((eintrag, index) => (
                            <li key={index}>
                                {eintrag.vorname} {eintrag.name}
                            </li>
                        ))
                    ) : (
                        <li>Keine Einträge vorhanden</li>
                    )}
                </ul>
            </div>
        );
    }
}

export default Dbread;


