
import React, { Component } from 'react';
import "./style.css"
import herbstBild from '../img/herbst.jpg';

class Bigbild extends Component {
    state= {}

    render() {
        return <>
        <div className="bigbild" style={{ backgroundImage: `url(${herbstBild})`, backgroundSize: `cover` }}>
            <div className="bigbild-text">
                <h3>Links</h3>
                <p>Verschiedene Links</p>
            </div>
        </div>
        </>
    }
}
export default Bigbild;